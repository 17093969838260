import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import { SystemSettingsContext } from "../../contexts/SystemSettingsContext";
import PrintAllRosteredLearners from "./PrintAllRosteredLearners";
import PrintableLearnerProgressReport from "./PrintableLearnerProgressReport";

const PrintAllRosteredLearnersContainer = ({
  learnersToPrintList,
  printReportComponent,
}) => {
  const [learnerList, setLearnerList] = useState([]);
  const { systemSettings } = useContext(SystemSettingsContext);

  useEffect(() => {
    formatLearnerList(learnersToPrintList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learnersToPrintList]);

  // Compile Reading Readiness and Number Readiness for Screened Learners
  const formatLearnerList = (learners) => {
    const formattedLearners = learners.map((learner) => {
      if (
        learner.lnuScores &&
        learner.lnuScores.length &&
        learner.lnlScores &&
        learner.lnlScores.length &&
        learner.lsiScores &&
        learner.lsiScores.length &&
        learner.rhymIdScores &&
        learner.rhymIdScores.length &&
        learner.rhymProdScores &&
        learner.rhymProdScores.length &&
        learner.numIdScores &&
        learner.numIdScores.length &&
        learner.countingScores &&
        learner.countingScores.length &&
        learner.fmScores &&
        learner.fmScores.length &&
        learner.sgwhScores &&
        learner.sgwhScores.length
      ) {
        // determine words missed from Rhyming Id Score Set
        const determineMissedRhymIdWords = (scoreSet) => {
          const missedWordsArray = [];
          if (scoreSet.bedFedMissed) {
            missedWordsArray.push("bed/fed");
          }
          if (scoreSet.beepDeepMissed) {
            missedWordsArray.push("beep/deep");
          }
          if (scoreSet.dadSadMissed) {
            missedWordsArray.push("dad/sad");
          }
          if (scoreSet.handCarMissed) {
            missedWordsArray.push("hand/car");
          }
          if (scoreSet.houseMoonMissed) {
            missedWordsArray.push("house/moon");
          }
          if (scoreSet.pigBigMissed) {
            missedWordsArray.push("pig/big");
          }
          if (scoreSet.sunCakeMissed) {
            missedWordsArray.push("sun/cake");
          }

          return missedWordsArray.toString();
        };

        // determine Fine Motor Score Set
        const determineMissedFMScores = (scoreSet) => {
          const missedFMScoresArray = [];

          if (
            scoreSet.fmCutHold !== "awkward" &&
            scoreSet.fmCutLine !== "unable"
          ) {
            missedFMScoresArray.push(
              `${learner.firstName} holds and uses scissors correctly. `
            );
          } else
            missedFMScoresArray.push(
              `${learner.firstName} should practice holding scissors correctly and cutting along a line. `
            );

          if (
            scoreSet.fmWritingName !== "noLetters" &&
            scoreSet.fmWritingName !== "atLeast2Letters" &&
            scoreSet.fmPenGrasp !== "awkward"
          ) {
            missedFMScoresArray.push(
              `${learner.firstName} can write their name correctly. `
            );
          } else
            missedFMScoresArray.push(
              `${learner.firstName} should practice writing their first name. `
            );

          return missedFMScoresArray.join(" ");
        };
        // determine words missed from Rhyming Prod Score Set
        const determineMissedRhymProdWords = (scoreSet) => {
          const missedWordsArray = [];
          if (scoreSet.henMissed) {
            missedWordsArray.push("hen");
          }
          if (scoreSet.mugMissed) {
            missedWordsArray.push("mug");
          }
          if (scoreSet.panMissed) {
            missedWordsArray.push("pan");
          }
          if (scoreSet.topMissed) {
            missedWordsArray.push("top");
          }
          if (scoreSet.wigMissed) {
            missedWordsArray.push("wig");
          }

          return missedWordsArray.toString();
        };

        // determines missing letters from a literacy score set
        const determineMissedLetters = (scoreSet) => {
          const missedLetterArray = [];
          if (scoreSet.aIsMissed) {
            missedLetterArray.push("a");
          }
          if (scoreSet.bIsMissed) {
            missedLetterArray.push("b");
          }
          if (scoreSet.cIsMissed) {
            missedLetterArray.push("c");
          }
          if (scoreSet.dIsMissed) {
            missedLetterArray.push("d");
          }
          if (scoreSet.eIsMissed) {
            missedLetterArray.push("e");
          }
          if (scoreSet.fIsMissed) {
            missedLetterArray.push("f");
          }
          if (scoreSet.gIsMissed) {
            missedLetterArray.push("g");
          }
          if (scoreSet.hIsMissed) {
            missedLetterArray.push("h");
          }
          if (scoreSet.iIsMissed) {
            missedLetterArray.push("i");
          }
          if (scoreSet.jIsMissed) {
            missedLetterArray.push("j");
          }
          if (scoreSet.kIsMissed) {
            missedLetterArray.push("k");
          }
          if (scoreSet.lIsMissed) {
            missedLetterArray.push("l");
          }
          if (scoreSet.mIsMissed) {
            missedLetterArray.push("m");
          }
          if (scoreSet.nIsMissed) {
            missedLetterArray.push("n");
          }
          if (scoreSet.oIsMissed) {
            missedLetterArray.push("o");
          }
          if (scoreSet.pIsMissed) {
            missedLetterArray.push("p");
          }
          if (scoreSet.qIsMissed) {
            missedLetterArray.push("q");
          }
          if (scoreSet.rIsMissed) {
            missedLetterArray.push("r");
          }
          if (scoreSet.sIsMissed) {
            missedLetterArray.push("s");
          }
          if (scoreSet.tIsMissed) {
            missedLetterArray.push("t");
          }
          if (scoreSet.uIsMissed) {
            missedLetterArray.push("u");
          }
          if (scoreSet.vIsMissed) {
            missedLetterArray.push("v");
          }
          if (scoreSet.wIsMissed) {
            missedLetterArray.push("w");
          }
          if (scoreSet.xIsMissed) {
            missedLetterArray.push("x");
          }
          if (scoreSet.yIsMissed) {
            missedLetterArray.push("y");
          }
          if (scoreSet.zIsMissed) {
            missedLetterArray.push("z");
          }

          return missedLetterArray.toString();
        };

        // determines missing numbers from a numeracy score set
        const determineMissedNumbers = (scoreSet) => {
          const missedNumbersArray = [];
          for (let i = 0; i < 21; i++) {
            if (scoreSet[`missed${i}`]) {
              missedNumbersArray.push(`${i}`);
            }
          }

          return missedNumbersArray.toString();
        };

        // compile the LNU score set for display
        const lnuScores =
          learner.lnuScores && learner.lnuScores.length
            ? learner.lnuScores.map((lnuScoreSet) => {
                return {
                  createdAt: lnuScoreSet.createdAt,
                  score: lnuScoreSet.score,
                  missedLetters: determineMissedLetters(lnuScoreSet),
                };
              })
            : null;

        // compile the LNL score set for display
        const lnlScores = learner.lnlScores.map((lnlScoreSet) => {
          return {
            createdAt: lnlScoreSet.createdAt,
            score: lnlScoreSet.score,
            missedLetters: determineMissedLetters(lnlScoreSet),
          };
        });

        // compile the LSI score set for display
        const lsiScores = learner.lsiScores.map((lsiScoreSet) => {
          return {
            createdAt: lsiScoreSet.createdAt,
            score: lsiScoreSet.score,
            missedLetters: determineMissedLetters(lsiScoreSet),
          };
        });

        // compile the Rhyming Id score set for display
        // Add variable Text based on Score
        const rhymIdScores = _.orderBy(
          learner.rhymIdScores,
          ["createdAt"],
          ["desc"]
        ).map((rhymIdScoreSet) => {
          return {
            createdAt: rhymIdScoreSet.createdAt,
            score: rhymIdScoreSet.score,
            missedWords: determineMissedRhymIdWords(rhymIdScoreSet),
            text:
              rhymIdScoreSet.score > 4
                ? `${learner.firstName} was able to successfully identify rhyming and non-rhyming words.`
                : `${learner.firstName} could use some practice identifying rhyming words such as he/me and dad/sad.  ${learner.firstName} should also be able to identify a word pair such as house/moon do not rhyme.`,
          };
        });

        // compile the Rhyming Prod score set for display

        const rhymProdScores = _.orderBy(
          learner.rhymProdScores,
          ["createdAt"],
          ["desc"]
        ).map((rhymProdScoreSet) => {
          return {
            createdAt: rhymProdScoreSet.createdAt,
            score: rhymProdScoreSet.score,
            missedWords: determineMissedRhymProdWords(rhymProdScoreSet),
            text:
              rhymProdScoreSet.score > 4
                ? `${learner.firstName} was able to successfully produce rhyming and non-rhyming words.`
                : `${learner.firstName} could use some practice producing rhyming a word with a given word. For instance if "top" is the given word, responding with a word that rhymes with top like "mop."`,
          };
        });

        // compile the counting score set for display
        const countingScores = learner.countingScores.map(
          (countingScoreSet) => {
            return {
              createdAt: countingScoreSet.createdAt,
              score: countingScoreSet.score,
              oneToOne: countingScoreSet.oneToOne === true ? "Yes" : "Not Yet",
            };
          }
        );

        // compile the numId score set for display
        const numIdScores = learner.numIdScores.map((numIdScoreSet) => {
          return {
            createdAt: numIdScoreSet.createdAt,
            score: numIdScoreSet.score,
            missedNumbers: determineMissedNumbers(numIdScoreSet),
          };
        });

        // compile the Fine Motor score set for display
        // Add variable Text based on Score
        const fmScores = learner.fmScores.map((fmScoreSet) => {
          return {
            createdAt: fmScoreSet.createdAt,
            text: determineMissedFMScores(fmScoreSet),
          };
        });

        // Compile SGWH Scores for display
        // insert readable text for scores and print
        const sgwhScores = learner.sgwhScores.map((sgwhScoreSet) => {
          return {
            selfHelp: sgwhScoreSet.selfHelp,
            createdAt: sgwhScoreSet.createdAt,
            scores: [
              {
                skill: "Safe body",
                score:
                  sgwhScoreSet.safeBody === "mostOfTheTime"
                    ? "Most of the time"
                    : sgwhScoreSet.safeBody === "someOfTheTime"
                    ? "Some of the time"
                    : "Not yet",
              },

              {
                skill: "Ready to learn",
                score:
                  sgwhScoreSet.readyToLearn === "mostOfTheTime"
                    ? "Most of the time"
                    : sgwhScoreSet.readyToLearn === "someOfTheTime"
                    ? "Some of the time"
                    : "Not yet",
              },
              {
                skill: "Share, take turns and wait our turn",
                score:
                  sgwhScoreSet.share === "mostOfTheTime"
                    ? "Most of the time"
                    : sgwhScoreSet.share === "someOfTheTime"
                    ? "Some of the time"
                    : "Not yet",
              },
              {
                skill: "Listen and follow directions",
                score:
                  sgwhScoreSet.listen === "mostOfTheTime"
                    ? "Most of the time"
                    : sgwhScoreSet.listen === "someOfTheTime"
                    ? "Some of the time"
                    : "Not yet",
              },
              {
                skill: "Good friend/kind words",
                score:
                  sgwhScoreSet.goodFriend === "mostOfTheTime"
                    ? "Most of the time"
                    : sgwhScoreSet.goodFriend === "someOfTheTime"
                    ? "Some of the time"
                    : "Not yet",
              },
              {
                skill: "Only worry about myself",
                score:
                  sgwhScoreSet.myself === "mostOfTheTime"
                    ? "Most of the time"
                    : sgwhScoreSet.myself === "someOfTheTime"
                    ? "Some of the time"
                    : "Not yet",
              },
              {
                skill: "Manages thoughts and feelings",
                score:
                  sgwhScoreSet.thoughts === "mostOfTheTime"
                    ? "Most of the time"
                    : sgwhScoreSet.thoughts === "someOfTheTime"
                    ? "Some of the time"
                    : "Not yet",
              },
              {
                skill: "Organizes and cleans up work and materials",
                score:
                  sgwhScoreSet.organized === "mostOfTheTime"
                    ? "Most of the time"
                    : sgwhScoreSet.organized === "someOfTheTime"
                    ? "Some of the time"
                    : "Not yet",
              },
              {
                skill: "Participates in learning",
                score:
                  sgwhScoreSet.participates === "mostOfTheTime"
                    ? "Most of the time"
                    : sgwhScoreSet.participates === "someOfTheTime"
                    ? "Some of the time"
                    : "Not yet",
              },
              {
                skill: "Completes work independently",

                score:
                  sgwhScoreSet.independant === "mostOfTheTime"
                    ? "Most of the time"
                    : sgwhScoreSet.independant === "someOfTheTime"
                    ? "Some of the time"
                    : "Not yet",
              },
            ],
          };
        });

        return {
          firstName: learner.firstName,
          lastName: learner.lastName,
          learnerId: learner.learnerId,
          birthday: learner.birthday,
          compiledLNUScores: lnuScores,
          compiledLNLScores: lnlScores,
          compiledLSIScores: lsiScores,
          compiledRhymIdScores: rhymIdScores,
          compiledRhymProdScores: rhymProdScores,
          compiledNumIdScores: numIdScores,
          compiledCountingScores: countingScores,
          compiledFmScores: fmScores,
          compiledSgwhScores: sgwhScores,
        };
      }
    });
    setLearnerList(_.compact(formattedLearners));
  };

  return (
    learnerList &&
    learnerList.length > 0 && (
      <PrintAllRosteredLearners
        learnerList={learnerList}
        ref={printReportComponent}
      />
    )
  );
};

export default PrintAllRosteredLearnersContainer;
